
















import { Component, Prop, Watch } from 'vue-property-decorator'
import { ControlConfig, ImageStore, QCReview } from '@ht-vue/quality-control'
import { JobSnap } from '@ht-lib/accounts-common'
import { UploadProgress } from '@ht-vue/image-transfer'
import { getUploader } from '../../uploader'
import { labUploadDataProvider } from '../../bookings'
import { heightUploadStepper } from '../../router/routes'
import Vue from '../../VueBase'

const qcConfig: ControlConfig = {
  save: {},
  finish: {},
  showFinishSave: false,
  inLab: false,
  showPrintWorksheet: false,
  height: heightUploadStepper
}

@Component({
  name: 'Images',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { QCReview, UploadProgress }
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) jobSnap!: JobSnap
  @Prop({ type: Boolean, required: false, default: false }) isLabUploader!: boolean
  qcConfig = qcConfig
  uploader = getUploader()
  showJobInfo = false

  get loaded (): boolean {
    return ImageStore.Instance.loaded
  }

  created (): void {
    const uid = this.isLabUploader ? labUploadDataProvider.getPhotographer().id : this.$auth.user.uid
    ImageStore.LoadNewJob(this.jobSnap, uid)
  }

  beforeDestroy () {
    ImageStore.ClearJob()
  }

  @Watch('uploader.isUploading', { immediate: true })
  onIsUploadingChanged (value: boolean): void {
    ImageStore.Instance.isUploading = value
  }
}
