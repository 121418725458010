










import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { PartialDeep, Job } from '@ht-lib/accounts-models'

@Component({
  name: 'Notes',
  components: {}
})
export default class extends Vue {
  @Prop({ required: true }) job!: Job

  update (notes: string): void {
    this.formUpdated(notes)
  }

  @Emit('input')
  formUpdated (notes: string): PartialDeep<Job> {
    return {
      notes
    }
  }
}
