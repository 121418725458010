"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const accounts_models_1 = require("@ht-lib/accounts-models");
const lodash_1 = require("lodash");
let default_1 = class default_1 extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.roles = Object.values(accounts_models_1.PhotographerRole);
    }
    get clone() {
        return (0, lodash_1.clone)(this.job);
    }
    roleUpdate(r, photographerId) {
        this.validate({
            [photographerId]: {
                role: r
            }
        });
    }
    get photographers() {
        return Object.values(this.job.photographersMap).sort((a, b) => a.name.localeCompare(b.name));
    }
    validate(photographersMap) {
        return { photographersMap };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], default_1.prototype, "job", void 0);
__decorate([
    (0, vue_property_decorator_1.Emit)('input')
], default_1.prototype, "validate", null);
default_1 = __decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'Photographers',
        components: {}
    })
], default_1);
exports.default = default_1;
